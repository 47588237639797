import React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Prose'


export default function BackupsPage() {
  const root = useClasses()
  return (
    <Layout>
      <SEO title='Backup' description='OneOffice backup and disaster recovery'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Backup & Disaster Recovery</h1>
        <h2 className="subtitle">Our strategy for backups, data-loss risk mitigation, and disaster recovery</h2>

        <section className='prose'>
            <h2>Introduction</h2>
            <p>
                Business continuity has always been a critical part of the design of OneOffice.
                We have spared no effort in ensuring that your data is safe, including if your own network is under attack and your team's computers are compromised.
            </p>

            <h3>Self-service</h3>
            <p>
                A major tenant of our design is self-service. 
                You don't want to be calling us when something happens, you want to be able to restore it yourself. 
                Even if our friendly and knowledgeable support team are always available, companies want to minimize risk and delay from third-party involvement.
            </p>

            <h3>Secure & Always Updated</h3>
            <p>
                We keep all our servers updated automatically, with no involvement by our staff.
                This provides automated protection agains zero-day attacks.
                We exclusively use Linux servers, reputed to be the most secure and robust servers. 
            </p>


            <h2>Live Monitoring</h2>
            <p>
                Before we go into the different types of disasters and how we recover, our experience has shown that we can "predict" most on-going risks in our infrastructure.
                Even if your data is backed up and safe, there are non-zero delays to restore should a disaster strike.
            </p>
            <p>
                We monitor each server real-time and can accurately identify risk elements in advance.
                These can be anything from byte rot, disk space, external attacks overloading the servers etc.
                By reacting in advance, we can eliminate a large number of serious issues before they occur, substantially reducing the likelihood of issues.
                We have perfected doing this with a large number of independent servers.
            </p>

            <h2>Types of Backups</h2>

            <h3>#0. Sync</h3>
            <p>
                While not really a backup, it provides some redundancy and shielding from localized issues (e.g. network, desktop crash etc.).
                Make sure you install the Sync application to keep your files up to date on your device.
                A caveat, if your device is compromised, your data may get stolen or spied on by third-party.
            </p>

            <h3>#1. Versioned Backups</h3>
            <p>
                File versions are automatically saved as you change or edit your documents. This is a really cool feature <b>only found on OneOffice</b>.
                And then you can <b>restore any version yourself at-will.</b>
                &nbsp;No need to call anyone.
                Here is how the backups work:
            </p>
            <ul>
                <li>For the first second we keep one version</li>
                <li>For the first 10 seconds we keep one version every 2 seconds</li>
                <li>For the first minute we keep one version every 10 seconds</li>
                <li>For the first hour we keep one version every minute</li>
                <li>For the first 24 hours we keep one version every hour</li>
                <li>For the first 30 days we keep one version every day</li>
                <li>After the first 30 days we keep one version every week</li>
                <li>After the first 60 days we keep one version per month</li>
                <li>After the first year we keep one version per year (for 3 years)</li>
            </ul>
            <p className='quote'>
                Saved versions never uses more that 50% of the user’s currently available free space.
                If the stored versions exceed this limit, OneOffice deletes the oldest versions until it meets the disk space limit again.
                A warning will be issued that you need more space, don't ignore it!
            </p>

            <h3>#2. Server Backup</h3>
            <p>
                We run full backups every night on the Cloud of the entire setup (Server, Disks, Network).
                This is typically in the same data center as the one your instance is residing in.
                This a brute-force backup which makes usable copies of the exiting setup (excluding cloud files & folders).
                This baclup is meant for quick system restore.
            </p>

            <h3>#3. Application Backup [Business Plan]</h3>
            <p>
                Your OneOffice instance is backed up (this is a redundancy of #2 -- but includes files and folders as well) every night as well.
                This is stored in a different data centre than the one you would be residing in.
                This provides a fall-back that is under our control so we can go back up quickly in case of data-center disaster (e.g. loss of connectivity, fire etc.).
            </p>

            <h3>#4. Data & Database Backup / Redundancy [Business Plan]</h3>
            <p>
                Typically, your files and folders are stored either in Object Based Storage or in Elastic Disk (regular disk).
                A second data center backup can be requested which provides a layer of redundancy to allow for quick disaster recovery.
                Note there is an additional fee due to the extra storage and management required.
            </p>
            <h2>Risks and Disaster Recovery</h2>
            <table className='w-full mt-10'>
                <thead>
                    <tr>
                        <th>Disaster</th>
                        <th>Recovery</th>
                        <th>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>Loss of file</b><br/>
                            A file is accidently deleted or overriden by the user
                        </td>
                        <td>#1&nbsp;-&nbsp;Versioned</td>
                        <td>Self-service</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Ransomware</b><br/>
                            Massive file hacking
                        </td>
                        <td>#1&nbsp;-&nbsp;Versioned<br/>#3&nbsp;-&nbsp;Application</td>
                        <td>Ransomware will not occur at the server-level, but on user devices. Sync-ed files get corrupted and pushed to the cloud.</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Hardware Failure</b><br/>
                            Cloud-service disruption
                        </td>
                        <td>#2&nbsp;-&nbsp;Server<br/>#3&nbsp;-&nbsp;Application<br/>#4&nbsp;-&nbsp;Data</td>
                        <td>Depending on the type of failure, a new server can be spun up quickly with the image backup, or a new server started from scratch and restored from Application backup.</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Desktop Crash /<br/>Connectivity Loss</b>
                        </td>
                        <td>#0&nbsp;-&nbsp;Sync</td>
                        <td>
                            The Sync app will restore all local files on user's desktop. Budget time and bandwith for restore process.<br/>
                            User can work on the cloud 100% until all files are restored on his/her desktop.
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>

        <CTA variant={1}/>
        
      </main>
    </Layout>

  )
}
